@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root {
    /* Solid Colors */
    --text-color: #000033;
    --background-color: #f2f9ff;
    --primary-color: #1534e7;
    --secondary-color: #0dd3f8;
    --accent-color: #1c57ff;
    --contrast-text-color: #ffffff;

    /* Gradients */
    --gradient-primary: linear-gradient(135deg, #1534e7, #0dd3f8); /* For primary elements */
    --gradient-accent: linear-gradient(135deg, #1534e7, #1c57ff); /* For accent elements */
    --gradient-background: linear-gradient(180deg, #000033, #1534e7); /* For page backgrounds */
    --gradient-hover: linear-gradient(135deg, #1c57ff, #0dd3f8); /* Hover state for buttons */
    --gradient-subtle: linear-gradient(90deg, #000033, #0dd3f8 50%, #1534e7); /* Subtle gradients for cards or sections */
}

[data-theme="dark"] {
    --text-color: #f2f9ff; /* Light text on dark background */
    --background-color: #000033; /* Deep dark blue background */
    --primary-color: #0dd3f8; /* Bright secondary for dark theme */
    --secondary-color: #1534e7; /* Use medium blue for accents */
    --accent-color: #1c57ff; /* Similar to light theme for consistency */
    --contrast-text-color: #000033; /* Darker text for readability in dark mode */
    --border-color: #0dd3f8; /* Lighter blue for borders */
    --input-background: #111133; /* Darker input background for theme contrast */
    --input-text-color: #f2f9ff; /* Light input text */
    --button-color: #0dd3f8; /* Secondary as button color */
    --button-hover-color: #1534e7; /* Primary as hover color */
}

[data-theme="light"] {
    --text-color: #000033; /* Main text color */
    --background-color: #f2f9ff; /* Slightly lightened blue for background */
    --primary-color: #1534e7; /* Use medium blue as primary */
    --secondary-color: #0dd3f8; /* Bright secondary for highlights */
    --accent-color: #1c57ff; /* Slightly brighter accent */
    --contrast-text-color: #ffffff; /* For text readability on primary/secondary backgrounds */
    --border-color: #1534e7; /* Consistent border color */
    --input-background: #ffffff; /* Neutral white input background */
    --input-text-color: #000033; /* Text color in inputs */
    --button-color: #1534e7; /* Button matches primary */
    --button-hover-color: #0dd3f8; /* Bright hover effect */
}


/*GRID LAYOUT*/
.layout {
    display: grid;
    grid-template-columns: minmax(150px, 250px) 1fr; /* Sidebar is between 150px and 250px */
    grid-template-rows: auto 1fr auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100vh;
    grid-template-areas:
        "header header"
        "sidebar main"
        "footer footer";
}


/* Sidebar */
.sidebar {
    grid-area: 1 / 1 / 4 / 2;
    padding: 1rem;
}

/* Header */
.header {
    grid-area: 1 / 2 / 2 / 4;
    padding: 1rem;
}

/* Main Content */
.main {
    grid-area: 2 / 2 / 3 / 4; 
    padding: 1rem;
}

/* Footer */
.footer {
    grid-area: 3 / 2 / 4 / 4; 
    padding: 1rem;
}


/* Example usage */
body {
    font-family: 'Roboto', sans-serif;
    color: var(--text-color);
    background: linear-gradient(180deg, #000033, #1534E7);
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

.loginButton {
    background-color: var(--primary-color);
    color: var(--text-color);

}

/* General Layout and Typography */
.body-text {
    color: var(--text-color);
}

.background {
    background-color: var(--background-color);
}

.heading-primary {
    color: var(--primary-color);
    font-size: 2rem;
    font-weight: bold;
}

.heading-secondary {
    color: var(--secondary-color);
    font-size: 1.5rem;
    font-weight: bold;
}

.accent-text {
    color: var(--accent-color);
}

/* Buttons */
.btn-primary {
    background-color: var(--primary-color);
    color: var(--contrast-text-color); /* Improved contrast */
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: darken(var(--primary-color), 10%);
    color: var(--contrast-text-color);
}

.btn-secondary {
    background-color: var(--secondary-color);
    color: var(--text-color);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
}

.btn-accent {
    background-color: var(--accent-color);
    color: var(--text-color);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
}


/* Forms */
.input-field {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--secondary-color);
    padding: 0.5rem;
    border-radius: 5px;
}

.label {
    color: var(--text-color);
}

.form-group {
    margin-bottom: 1rem;
}

.error-message {
    color: var(--accent-color);
    font-size: 0.875rem;
}

/* Alerts */
.alert-primary {
    background-color: var(--primary-color);
    color: var(--text-color);
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.alert-secondary {
    background-color: var(--secondary-color);
    color: var(--text-color);
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.alert-accent {
    background-color: var(--accent-color);
    color: var(--text-color);
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}

/*FIX THIS IN HEADER INSTEAD*/
.theme-toggle {
    background-color: var(--primary-color);
    color: var(--contrast-text-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.theme-toggle:hover {
    background-color: var(--secondary-color);
}

.container {
    /* display: grid; */
    /* min-height: 86vh; */
    grid-template-columns: 1fr; /* Single column layout for mobile */
    grid-template-rows: auto; /* Automatic height for rows */
    gap: 20px; /* Space between grid items */
    max-width: 1200px; /* Maximum width of the container */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Padding around the container */
}



/**/

@media (min-width: 600px) {
    .container {
        grid-template-columns: 1fr 1fr; /* Two-column layout for tablets */
    }
}

@media (min-width: 1200px) {
    .container {
        grid-template-columns: repeat(3, 1fr);
    }
}


h1 {
    grid-column: span 1; /* Span the first column */
    text-align: center; /* Center the title */
}

.features {
    grid-column: span 1; /* Span the first column */
}

.button-container {
    grid-column: span 1; /* Span the first column */
    display: flex; /* Flexbox for buttons */
    justify-content: center; /* Center buttons */
    gap: 10px; /* Space between buttons */
}

/* .footer {
    grid-column: span 1; 
    text-align: center;
    position:absolute;
    left:0;
    bottom:0;
    right:0;
} */

/* Additional styles for buttons, features, etc. */
.primary {
    background-color: var(--primary-color);
    color: var(--contrast-text-color);
}

.secondary {
    background-color: var(--secondary-color);
    color: var(--text-color);
}


/*Team home*/
.team-home-container {
    padding: 20px;
    text-align: center;
}

.member-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.member-card {
    width: 200px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.member-image {
    border-radius: 10px;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.member-info {
    font-size: 14px;
}

.admin-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.admin-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.staff-details {
    margin-bottom: 10px;
} 

/* MODALS */
.admin-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
}
