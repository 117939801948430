/* Container */
.form-builder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* background-color: var(--background-color); */
    color: var(--text-color);
    min-height: 87vh;

}

/* Main Content */
.form-builder-content {
    width: 100%;
    max-width: 800px;
    background: var(--contrast-text-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Title */
.form-builder-title {
    font-family: 'Bebas Neue', cursive;
    font-size: 2.5rem;
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 20px;
}

/* Form Name */
.form-name-section {
    margin-bottom: 20px;
}

.form-name-input {
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
}

/* New Field Section */
.new-field-section {
    margin-bottom: 20px;
}

.section-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--text-color);
}

.field-type-select select {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    margin-bottom: 10px;
}

.field-label-input, .field-options-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
}

/* Buttons */
.add-field-btn, .save-form-btn {
    background-color: var(--primary-color);
    color: var(--contrast-text-color);
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.add-field-btn:hover, .save-form-btn:hover {
    background-color: var(--secondary-color);
}

/* Form Preview */
.form-preview-section {
    margin-bottom: 20px;
}

.preview-field {
    margin-bottom: 10px;
}

.preview-label {
    font-weight: bold;
    color: var(--text-color);
}


.form-list-table {
    color: var(--text-color);
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-family: 'Arial', sans-serif;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-list-table th,
.form-list-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid var(--secondary-color);
}

.form-list-table th {
    background-color: var(--primary-color);
    color: var(--contrast-text-color);
    font-weight: bold;
    text-transform: uppercase;
}

.form-list-table tbody tr:nth-child(even) {
    background-color: var(--gradient-subtle);
}

.form-list-table tbody tr:hover {
    background-color: var(--gradient-hover);
}

.form-list-table a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
}

.form-list-table a:hover {
    text-decoration: underline;
}

.form-list-table td[colspan="4"] {
    text-align: center;
    font-style: italic;
    color: var(--text-color);
    padding: 15px;
}
