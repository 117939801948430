

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: var(--background-color);
    color: var(--text-color);
    border-top: 2px solid var(--primary-color);
    font-family: 'Roboto', sans-serif;
}

.footer p {
    margin: 0;
    font-size: 0.9em;
    color: var(--secondary-color);
}

.footer a {
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 500;
    margin-left: 1em;
    transition: color 0.3s ease;
}

.footer a:hover {
    color: var(--accent-color);
}

.footer-links {
    display: flex;
    gap: 1em;
    align-items: center;
}
