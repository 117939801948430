.profile-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.page-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: var(--text-color);
}

.profile-info {
    font-size: 18px;
    color: var(--text-color);
}

.profile-info p {
    margin: 10px 0;
}

.discord-connect {
    margin-top: 20px;
    text-align: center;
}

.discord-connect h3 {
    margin-bottom: 10px;
    font-size: 20px;
    color: var(--text-color);
}

.discord-connect p {
    display: inline-block;
    margin-right: 10px;
}

.discord-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.btn-primary {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: var(--contrast-text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: var(--button-hover-color);
}