.header {
    /* grid-area: header; */

    /* display: flex; */
    /* align-items: center; */
    background-color: var(--header-bg);
    padding: 10px 20px;
    border-bottom: 1px solid var(--header-border);
    text-decoration: none;
}

.header h1 {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin: 0;
}

.header-container {
    display: flex;
    align-items: center;
    /* width: 100%; */
    /*  */
}

.header-logo {
    width: 18em;
    height: 4em;
}

.spacer {
    flex-grow: 1;
}

.theme-toggle, 
.inbox-button, 
.account-button {
    background: none;
    border: none;
    margin: 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 3em;
    color: white;
}

.theme-toggle svg,
.inbox-button svg{
    width: 60px;
    height: 40px;

    stroke: white;
}

/* .account-button svg  {
    width: 60px;
    height: 40px;
    fill: white;
} */



.username {
    font-size: 1rem;
    margin: 0 10px;
    color: var(--text-color);
}

@media (max-width: 768px) {
    .header-logo {
        width: 200px;
        height: auto;
    }

    .username {
        font-size: 0.9rem;
    }

}
