.modal {
    position: fixed; /* Positioned relative to the viewport */
    top: 8%; /* Offset from the top of the screen */
    /* left: 88%; */
    right: 2%; /* Always 5% away from the right edge */
    background-color: var(--background-color); /* Use your gradient */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1rem;
    z-index: 1000; /* Ensure it's above other content */
    width: 200px;
    height: 290px;
    animation: fadeIn 0.3s ease-in-out; /* Add a fade-in animation */
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    color: var(--text-color); /* Use text color from theme */
}

.modal-close-btn {
    position: absolute;
    /* top: 10px; */
    right: 10px;
    background: none;
    border: none;
    color: var(--text-color); /* Close button matches text color */
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.modal-close-btn:hover {
    transform: rotate(90deg); /* Fun rotation on hover */
    color: var(--accent-color); /* Accent color on hover */
}

.modal-options {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
}

.modal-options li {
    margin: 10px 0;
}

.modal-options a,
.logout-btn {
    text-decoration: none;
    color: var(--primary-color); /* Primary color for links */
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 4px;
    display: block; /* Full-width clickable area */
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.modal-options a:hover,
.logout-btn:hover {
    background: var(--gradient-hover); /* Hover gradient */
    color: var(--contrast-text-color); /* Contrasting text color */
}

.logout-btn {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    text-align: left; /* Align logout button with text */
    margin-top: 10px;
    padding: 0.5rem;
    border-radius: 4px;
    width: 100%;
}

.logout-btn:hover {
    background: var(--gradient-accent); /* Accent gradient for hover */
    color: var(--contrast-text-color);
}

.logout-btn svg {
    width: 12px;
    height: 12px;
}