:root {
    /* Solid Colors */
    --text-color: #000033;
    --background-color: #f2f9ff;
    --primary-color: #1534e7;
    --secondary-color: #0dd3f8;
    --accent-color: #1c57ff;
    --contrast-text-color: #ffffff;

    /* Gradients */
    --gradient-primary: linear-gradient(135deg, #1534e7, #0dd3f8); /* For primary elements */
    --gradient-accent: linear-gradient(135deg, #1534e7, #1c57ff); /* For accent elements */
    --gradient-background: linear-gradient(180deg, #000033, #1534e7); /* For page backgrounds */
    --gradient-hover: linear-gradient(135deg, #1c57ff, #0dd3f8); /* Hover state for buttons */
    --gradient-subtle: linear-gradient(90deg, #000033, #0dd3f8 50%, #1534e7); /* Subtle gradients for cards or sections */
}

[data-theme="dark"] {
    --text-color: #f2f9ff; /* Light text on dark background */
    --background-color: #000033; /* Deep dark blue background */
    --primary-color: #0dd3f8; /* Bright secondary for dark theme */
    --secondary-color: #1534e7; /* Use medium blue for accents */
    --accent-color: #1c57ff; /* Similar to light theme for consistency */
    --contrast-text-color: #000033; /* Darker text for readability in dark mode */
    --border-color: #0dd3f8; /* Lighter blue for borders */
    --input-background: #111133; /* Darker input background for theme contrast */
    --input-text-color: #f2f9ff; /* Light input text */
    --button-color: #0dd3f8; /* Secondary as button color */
    --button-hover-color: #1534e7; /* Primary as hover color */
}

[data-theme="light"] {
    --text-color: #000033; /* Main text color */
    --background-color: #f2f9ff; /* Slightly lightened blue for background */
    --primary-color: #1534e7; /* Use medium blue as primary */
    --secondary-color: #0dd3f8; /* Bright secondary for highlights */
    --accent-color: #1c57ff; /* Slightly brighter accent */
    --contrast-text-color: #ffffff; /* For text readability on primary/secondary backgrounds */
    --border-color: #1534e7; /* Consistent border color */
    --input-background: #ffffff; /* Neutral white input background */
    --input-text-color: #000033; /* Text color in inputs */
    --button-color: #1534e7; /* Button matches primary */
    --button-hover-color: #0dd3f8; /* Bright hover effect */
}

.info-link {
    display: block;
    margin-bottom: 20px;
    color: var(--primary-color);
    text-decoration: none;
}

.tickets-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.ticket-card {
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 20px;
    flex: 1 1 calc(20% - 40px); /* Adjusted width */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ticket-card.open {
    border-left: 5px solid green;
}

.ticket-card.claimed {
    border-left: 5px solid orange;
}

.ticket-card.closed {
    border-left: 5px solid red;
}

.ticket-buttons {
    display: flex;
    gap: 10px;
}

.ticket-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.ticket-buttons .claim-button {
    background-color: blue;
    color: white;
}

.ticket-buttons .close-button {
    background-color: red;
    color: white;
}

.ticket-buttons button:disabled {
    background-color: grey;
    cursor: not-allowed;
}

.ticket-card h3 {
    margin-top: 0;
    color: var(--text-color);
}

.ticket-card p {
    margin: 5px 0;
    color: var(--text-color);
}

.ticket-card a {
    color: var(--primary-color);
    text-decoration: none;
    cursor: hand;
}

.ticket-card a :hover {
    cursor: pointer;
    color: red;
}

.transcript-link {
    color: blue;
    text-decoration: underline;
}

.transcript-link:hover {
    color: darkblue;
    text-decoration: none;
}

.controls {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.controls label {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--text-color);
}

.controls select {
    padding: 5px 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--input-background);
    color: var(--input-text-color);
    cursor: pointer;
}

.controls select:focus {
    outline: none;
    border-color: var(--primary-color);
}

.claim-button {
    background-color: var(--button-color);
    color: var(--contrast-text-color);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.claim-button:hover {
    background-color: var(--button-hover-color);
}

.close-button {
    background-color: red;
    color: var(--contrast-text-color);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
}

.close-button:hover {
    background-color: darkred;
}