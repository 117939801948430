.sidebar {
    /* width: 250px; */
    /* height: 100vh; */
    background-color: #2c3e50; /* Dark blue-gray */
    color: white;
    /* display: flex;
    flex-direction: column;
    position: fixed; */
    /* left: 0; */
    /* top: 0; */
    padding: 20px;
}

.sidebar-container {
    display: flex;
    flex-direction: column;
}

.team-info {
    text-align: center;
    margin-bottom: 20px;
}

.team-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.team-name {
    font-size: 18px;
    font-weight: bold;
}

.sidebar-section {
    margin-bottom: 20px;
}

.sidebar-section h3 {
    font-size: 16px;
    margin-bottom: 10px;
    text-transform: uppercase;
    border-bottom: 1px solid #34495e;
    padding-bottom: 5px;
}

.sidebar-section ul {
    list-style: none;
    padding: 0;
}

.sidebar-section li {
    margin: 5px 0;
}

.sidebar-section a {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
    display: block;
    transition: background-color 0.2s ease;
}

.sidebar-section a:hover {
    background-color: #34495e; /* Slightly lighter blue-gray */
}
