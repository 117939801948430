.register-wrapper,
.login-wrapper {
    max-width: 400px;
    margin: 50px auto;
    padding: 30px;
    background-color: var(--background-color);
    border-radius: 12px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    /* min-height: 100vh; Ensures content takes the full height */
}

.login-logo img {
    max-width: 120px;
    margin-bottom: 20px;
}

h2 {
    font-size: 28px;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 10px;
}

.subtitle {
    font-size: 16px;
    color: var(--secondary-text-color);
    margin-bottom: 20px;
}

.custom-input {
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    width: 90%;
}

.custom-input:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 128, 255, 0.5);
}

.custom-button {
    padding: 15px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.custom-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.custom-button:hover:not(:disabled) {
    background-color: var(--secondary-color);
    transform: scale(1.02);
}

.custom-message {
    color: var(--error-color);
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}

.custom-link {
    text-align: center;
    color: var(--primary-color);
    font-size: 16px;
    cursor: pointer;
    margin-top: 15px;
    display: inline-block;
}

.custom-link:hover {
    text-decoration: underline;
}

.newsletter-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    color: var(--text-color);
}

.newsletter-checkbox input {
    margin-right: 10px;
}
